/*
 * 23-01-2022 MDA-363 LuânĐT login with google authen
 */
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../stores/actions/userActions";
import { useGoogleLogin } from "@react-oauth/google";
import "../assets/css/LoginPage.scss";
import logo from "../assets/images/logo.jpeg";

const LoginPage = (props) => {
  const dispatch = useDispatch();

  const isLogin = useSelector((state) => state.authenticationReducer.isLogin);
  const loggingIn = useSelector(
    (state) => state.authenticationReducer.loggingIn
  );
  const from = props.location.state ? props.location.state.from.pathname : "/";
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });

  const { username, password } = inputs;
  const [submitted, setSubmitted] = useState(false);
  const [visibilityPass, setvisibilityPass] = useState(true);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (username.trim() && password.trim()) {
      dispatch(userActions.login(username.trim(), password.trim()));
    }
  }

  const login = useGoogleLogin({
    onSuccess: tokenResponse => responseGoogle(tokenResponse),
    onError: (err) => console.log(err)
  });

  const buttonEnable = username.trim() && password.trim();

  const responseGoogle = (response) => {
    console.log(response)

    localStorage.setItem(
      "current_email",
      JSON.stringify(response?.hd)
    );
    if (response?.access_token) {
      localStorage.setItem(
        "access_token",
        JSON.stringify(response?.access_token)
      );
      window.location.href = "/";
    }
  };

  const renderLoginPage = () => {
    return (
      <div className="container row login-box">
        <div className="col login-form">
          <div className="avatar">
            <img src={logo} alt="" />
          </div>
          <form name="form" onSubmit={handleSubmit}>
            <label className="login">
              <input
                placeholder="Username"
                name="username"
                value={username}
                onChange={handleChange}
                className={
                  "form-control" +
                  (submitted && !username ? " is-invalid" : " lk-code-input")
                }
              />
            </label>
            <label className="passwd">
              <input
                placeholder="Mật khẩu test"
                type={visibilityPass ? "password" : "text"}
                name="password"
                value={password}
                onChange={handleChange}
                className={
                  "form-control" +
                  (submitted && !password
                    ? " is-invalid"
                    : " lk-password-input")
                }
              />
            </label>
            <div
              className={`form-group show-password ${
                !visibilityPass ? "active" : ""
              }`}
            >
              <div
                onClick={() => setvisibilityPass(!visibilityPass)}
                className={`checkbox ${!visibilityPass ? "active" : ""}`}
              />
              <span onClick={() => setvisibilityPass(!visibilityPass)}>
                Hiển thị mật khẩu
              </span>
            </div>
            <button
              type="submit"
              className={`button btn btn-lg lika-login-btn ${
                buttonEnable ? "active" : ""
              }`}
              disabled={loggingIn}
            >
              {loggingIn && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              <span>Đăng nhập</span>
            </button>
            <p>Hoặc</p>
            <button
              type="button"
              className="login-with-google-btn"
              // onClick={renderProps.onClick}
              // disabled={renderProps.disabled}
              onClick={() => {
                login()
              }}
            >
              Đăng nhập bằng Google
            </button>
            {/* <GoogleLogin
              clientId="344199087418-vds313j43gru6q3sb0csfs60cm9fu07v.apps.googleusercontent.com"
              render={(renderProps) => (
                <button
                  type="button"
                  className="login-with-google-btn"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  Đăng nhập bằng Google
                </button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            /> */}
          </form>
        </div>
      </div>
    );
  };
  const redirectToPage = () => {
    return <Redirect to={from} />;
  };
  return (
    <React.Fragment>
      {isLogin ? redirectToPage() : renderLoginPage()}
    </React.Fragment>
  );
};

export { LoginPage };
