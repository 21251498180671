import React from "react";
import { Row, Col } from "antd";
import "../assets/notFound.scss";

const NotFoundPage = () => {
  return (
    <Row justify="center" align="middle" className="notfound">
      <Col span={24}>
        <Row justify="center">
          {/* <img src={mascotIcon} alt="404Gif" className="notfound-mascot" /> */}
        </Row>
        <Row justify="center">
          <span className="notfound-title">Oops!</span>
        </Row>
        <Row justify="center">
          <span className="notfound-desc">Trang bạn tìm kiếm không thể tìm thấy.</span>
        </Row>
        <Row justify="center">
          {/* <CommonLink text={"VỀ TRANG CHỦ"} url={`/student/homepage/${grade_id}/${subject_id}`} /> */}
        </Row>
      </Col>
    </Row>
  );
};

export default NotFoundPage;
