import React, { useState, useEffect } from "react";

function Header() {
  return (
    <header id="root-header" className="header-page">
      {/* <div>test</div> */}
    </header>
  );
}

export default React.memo(Header);
