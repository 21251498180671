import { setToken } from "../utils/commonHttp";
import { notification } from "antd";
export const userService = {
  login,
};

async function login(usernameOrEmail, password) {
  const url =
    "https://cloud-gateway.clevai.edu.vn/api/v1/customer/users/signin";

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ usernameOrEmail, password }),
  };

  const response = await fetch(url, requestOptions);
  const user = await handleResponse(response);
  setToken(user.data.access_token);
  localStorage.setItem("access_token", JSON.stringify(user.data.access_token));
  localStorage.setItem("current_user", JSON.stringify(user.data));

  return [user];
}

function handleResponse(response, checkGetInfo) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // logout();
        // location.reload(true);
      }
      if (response.status === 403) {
        notification["error"]({
          message: "Có lỗi xảy ra",
          description: "Username hoặc mật khẩu không đúng. Vui lòng nhập lại!",
        });
      }
      let error = (data && data.message) || response.statusText;

      return Promise.reject(error);
    } else {
      return data;
    }
  });
}
