import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { history } from "./helper/history";
import { AppLayout, PrivateRoute } from "./modules/common";
import { useDispatch, useSelector } from "react-redux";
import { LoginPage } from "./modules/login";
import { HomePage } from "./modules/home";
import { NotFoundPage } from "./modules/base";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "antd/dist/antd.css";
import "./App.css";

function App() {
  return (
    <GoogleOAuthProvider clientId="344199087418-vds313j43gru6q3sb0csfs60cm9fu07v.apps.googleusercontent.com">
      <Router history={history}>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <AppLayout
            id="homepage-report-list"
            containerStyle={{ height: "100%", overflow: "hidden" }}
          >
            <Switch>
              <PrivateRoute path="/" component={HomePage} />
              <PrivateRoute path="*" component={NotFoundPage} />
            </Switch>
          </AppLayout>
        </Switch>
      </Router>
    </GoogleOAuthProvider>
  );
}
export default App;
