/*
 * 23-01-2022 MDA-363 LuânĐT login with google authen
 * 25-03-2022 MDA-381 LuânĐT change left menu structure
 * 29-03-2022 MDA-415 LuânĐT add ccr report
 * 04-04-2022 MDA-461 LuânĐT add menu for dev
 * 07-04-2022 MDA-523 LuânĐT add mps report
 * 13-06-2022 MDA-714 LuânĐT add marketing report dev
 */
import React, { useEffect, useState } from "react";
import { Layout, Menu, Avatar, Dropdown } from "antd";
import {
  UserOutlined,
  PieChartOutlined,
  LineChartOutlined,
  IssuesCloseOutlined,
  AimOutlined,
  HddFilled,
  HeartFilled
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { DATA_REPORT } from "../../../utils/constants";
import { ReportFrame, PermissionReject } from "../components";
import { googleLogout } from "@react-oauth/google";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const CURRENT_TAB_ACTIVE = "CURRENT_TAB_ACTIVE";
const IS_COLLAPSED = "IS_COLLAPSED";
const CURRENT_EMAIL = "current_email";

function HomepageWrap() {
  const [collapsed, setCollapsed] = useState(true);
  const [link, setLink] = React.useState("");
  const activeTab = localStorage.getItem(CURRENT_TAB_ACTIVE);
  const isCollapsed = localStorage.getItem(IS_COLLAPSED);
  const currentEmail = localStorage.getItem(CURRENT_EMAIL);

  useEffect(() => {
    activeTab ? getLinkReport(activeTab) : getLinkReport(1);

    isCollapsed
      ? setCollapsed(JSON.parse(isCollapsed))
      : setCollapsed(collapsed);
  }, []);

  const handleChangeContent = ({ item, key }) => {
    getLinkReport(key);
    localStorage.setItem(CURRENT_TAB_ACTIVE, key);
  };

  const handleCollapse = () => {
    setCollapsed(!collapsed);
    localStorage.setItem(IS_COLLAPSED, !collapsed);
  };

  const getLinkReport = (id) => {
    let data = DATA_REPORT.find((data) => data.id == id);
    setLink(data?.link);
  };

  const logout = () => {
    localStorage.clear();
    googleLogout()
    window.location.href = "/login";
  };

  const userMenu = (
    <Menu>
      <Menu.Item>
        <div onClick={() => logout()}>Đăng xuất</div>
      </Menu.Item>
    </Menu>
  );

  const user = useSelector((state) => state.authenticationReducer.user);

  return (
    <div className="App">
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={() => handleCollapse()}
          width={250}
        >
          <div
            className="logo"
            style={{
              height: "28px",
              margin: "16px",
            }}
          >
            <img
              src="https://r73troypb4obj.vcdn.cloud/picture/icons/login_clevai_logo.svg"
              style={{ width: "100px" }}
            />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={activeTab ? [activeTab] : ["2"]}
            onSelect={(item, key) => handleChangeContent(item, key)}
            width={250}
          >
            <SubMenu
              key="sub1"
              icon={<LineChartOutlined />}
              title="Báo cáo giao ban TM+"
            >
              <Menu.Item key="1">CATS5</Menu.Item>
              <Menu.Item key="2">Sale</Menu.Item>
              <Menu.Item key="4">Marketing</Menu.Item>
              <Menu.Item key="3">Operation</Menu.Item>
              <Menu.Item key="5">Finance</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub2"
              icon={<UserOutlined />}
              title="Báo cáo vận hành"
            >
              {/* <Menu.Item key="1">Student Engagement (DWR)</Menu.Item> */}
              <Menu.Item key="6">COR 100</Menu.Item>
              {/* <Menu.Item key="7">MSS Get Session</Menu.Item> */}
              <Menu.Item key="8">MPS Get Session</Menu.Item>
              <Menu.Item key="9">CCR</Menu.Item>
              <Menu.Item key="14">Session Report</Menu.Item>
              <Menu.Item key="15">Event Monitoring</Menu.Item>
              <Menu.Item key="16">AI Model Monitoring</Menu.Item>
              <Menu.Item key="17">Teacher and Curriculum</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub3"
              icon={<PieChartOutlined />}
              title="Báo cáo Board"
            ></SubMenu>
            {user?.roles.includes("crs_dev") && (
              <SubMenu
                key="sub4"
                icon={<IssuesCloseOutlined />}
                title="Báo cáo Dev"
              >
                <Menu.Item key="10">Chỉ số Engagment Dev</Menu.Item>
                <Menu.Item key="11">Chỉ số Kinh doanh</Menu.Item>
                <Menu.Item key="12">Marketing dev</Menu.Item>
                <Menu.Item key="13">Sale dev</Menu.Item>
              </SubMenu>
            )}
            <SubMenu
              key="sub5"
              icon={<AimOutlined />}
              title="Dash 200"
            >
              <Menu.Item icon={<HddFilled />} key="18">Campaign report</Menu.Item>
              <Menu.Item icon={<HeartFilled />} key="19">Calculated KR</Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "0 30px",
            }}
          >
            <Dropdown overlay={userMenu}>
              <Avatar size={32} icon={<UserOutlined />} />
            </Dropdown>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              minHeight: 280,
            }}
          >
            {activeTab == 5 ? (
              user?.roles.includes("crs_admin") ||
              JSON.parse(currentEmail) == "tuanpm@clevai.edu.vn" ? (
                <ReportFrame link={link} />
              ) : (
                <PermissionReject />
              )
            ) : (
              <ReportFrame link={link} />
            )}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
export default HomepageWrap;
