import { userConstants } from "../../constants/userConstants";
import { userService } from "../../service/userService";

export const userActions = {
  login,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      (data) => {
        window.location.href = "/";
      },
      (error) => {
        dispatch(failure(error ? error.toString() : "NOT_FOUND"));
      }
    );
  };
}

function request(user) {
  return { type: userConstants.LOGIN_REQUEST, user };
}
function success(user) {
  return { type: userConstants.LOGIN_SUCCESS, user };
}
function failure(error) {
  return { type: userConstants.LOGIN_FAILURE, error };
}
