import { userConstants } from "../../constants/userConstants";
import ReducerRegistry from "../../app/redux/ReducerRegistry";

let user = JSON.parse(localStorage.getItem("current_user"));
const initialState = user
  ? {
      loggedIn: true,
      isLogin: false,
      failureLogin: false,
      loginForbidden: false,
      loginWaitingActivation: false,
      loginGone: false,
      loginNotFound: false,
      user,
      userInfo: {},
    }
  : {};

ReducerRegistry.register(
  "authenticationReducer",
  (state = initialState, action) => {
    switch (action.type) {
      case userConstants.LOGIN_REQUEST:
        return {
          loggingIn: true,
          user: action.user,
        };
      case userConstants.LOGIN_SUCCESS:
        return {
          loggedIn: true,
          isLogin: true,
          loggingIn: false,
          user: action.user,
        };
      case userConstants.LOGIN_FAILURE:
        if (action.error === "WAITING_ACTIVATION") {
          return {
            ...state,
            loggingIn: false,
            loginWaitingActivation: true,
          };
        }
        if (action.error === "Forbidden") {
          return {
            ...state,
            loggingIn: false,
            loginForbidden: true,
          };
        }
        if (action.error === "Gone") {
          return {
            ...state,
            loggingIn: false,
            loginGone: true,
          };
        }
        if (action.error === "INFO_ERROR") {
          return {
            ...state,
            loggingIn: false,
            loginNotFound: true,
          };
        }
        return {
          ...state,
          loggingIn: false,
          failureLogin: true,
        };
      case userConstants.LOGOUT:
        return {};
      case userConstants.GET_USER_INFO_SUCCESS:
        return {
          ...state,
          userInfo: action.payload,
        };
      case userConstants.GET_USER_INFO_ERROR:
        return {
          ...state,
          userInfo: action.error,
        };
      case userConstants.UPDATE_PROFILE_SUCCESS:
        return {
          ...state,
          user: action.payload,
        };
      default:
        return state;
    }
  }
);
