import axios from "axios";
import _ from "lodash";

let getHeaders = {
  Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`,
};

export const setToken = (token) => {
  getHeaders.Authorization = token;
};

export const httpGET = (url, params, successCallback, failCallback) => {
  return axios
    .get(url, {
      params,
      headers: getHeaders,
    })
    .then((response) => {
      successCallback(response.data);
    })
    .catch((error) => {
      if (error.response && error.response.status == 401) {
        localStorage.clear();
        window.location.href = "/";
      }
      failCallback(error);
    });
};

export const httpGETCUSTOM = (url, params, successCallback, failCallback) => {
  return axios
    .get(url, {
      params,
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`,
      },
    })
    .then((response) => {
      successCallback(response.data);
    })
    .catch((error) => {
      if (error.response && error.response.status == 401) {
        localStorage.clear();
        window.location.href = "/";
      }
      failCallback(error);
    });
};

export const httpPUT = (url, params, successCallback, failCallback) => {
  return axios
    .put(url, params, {
      headers: getHeaders,
    })
    .then((response) => {
      successCallback(response.data);
    })
    .catch((error) => {
      failCallback(error);
    });
};

export const httpPOST = (url, params, successCallback, failCallback) => {
  return axios({
    method: "POST",
    headers: getHeaders,
    data: params,
    url,
  })
    .then((response) => {
      successCallback(response.data);
    })
    .catch((error) => {
      failCallback(error);
    });
};

export const httpPOSTUnauthenticated = (
  url,
  params,
  successCallback,
  failCallback
) => {
  return axios({
    method: "POST",
    data: params,
    url,
  })
    .then((response) => {
      successCallback(response.data);
    })
    .catch((error) => {
      failCallback(error);
    });
};
