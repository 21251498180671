/*
 * 24-01-2022 MDA-369 LuânĐT fix bug default page PowerBI
 * 25-03-2022 MDA-381 LuânĐT change left menu structure add shorten link report
 * 29-03-2022 MDA-415 LuânĐT add ccr report
 * 04-04-2022 MDA-461 LuânĐT add menu for dev
 * 07-04-2022 MDA-523 LuânĐT add mps report
 * 13-06-2022 MDA-714 LuânĐT add marketing report dev
 */
export const DATA_REPORT = [
  {
    id: 1,
    name: "CATS5",
    link: "https://clev.ai/crs_cats5",
  },
  {
    id: 2,
    name: "Clevai Report (CRS): Sale",
    link: "https://clev.ai/crs_sale",
  },
  {
    id: 3,
    name: "Clevai Report (CRS): Engagement",
    link: "https://clev.ai/crs_engagement",
  },
  {
    id: 4,
    name: "Clevai Report (CRS): Marketing",
    link: "https://clev.ai/crs_marketing",
  },
  {
    id: 5,
    name: "Clevai Report (CRS): Finance",
    link: "https://clev.ai/crs_finance",
  },
  {
    id: 6,
    name: "Operation Report: COR100",
    link: "https://clev.ai/crs_cor_100",
  },
  {
    id: 7,
    name: "Operation Report: MSS_GET_SESSION",
    link: "https://clev.ai/crs_mss_get_session",
  },
  {
    id: 8,
    name: "Operation Report: MPS_GET_SESSION",
    link: "https://clev.ai/crs_mps_get_session",
  },
  {
    id: 9,
    name: "Operation Report: CCR",
    link: "https://clev.ai/crs_ccr",
  },
  {
    id: 10,
    name: "Chỉ số Engagment Dev",
    link: "https://clev.ai/crs_dashdev",
  },
  {
    id: 11,
    name: "01. Các chỉ số GB/GR",
    link: "https://clev.ai/gb_gr_dev",
  },
  {
    id: 12,
    name: "Marketing dev",
    link: "https://clev.ai/crs_mar_dev",
  },
  {
    id: 13,
    name: "Sale dev",
    link: "https://clev.ai/crs_sale_dev",
  },
  {
    id: 14,
    name: "Session Report",
    link: "https://clev.ai/crs_session_report",
  },
  {
    id: 15,
    name: "Event Monitoring",
    link: "https://clev.ai/crs_event_monitoring",
  },
  {
    id: 16,
    name: "AI Model Monitoring",
    link: "https://clev.ai/ai_model_monitoring",
  },
  {
    id: 17,
    name: "Teacher and Curriculum",
    link: "https://clev.ai/crs_teacher_curriculum",
  },
  {
    id: 18,
    name: "Campaign report",
    link: "https://clev.ai/dash200",
  },
  {
    id: 19,
    name: "Calculated KR",
    link: "https://clev.ai/calculate_kr",
  },
];
