import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: ComponentChild, children, ...rest }) => {
  const Component = children ? children : <ComponentChild />;

  const checkPermission = () => {
    return localStorage.getItem("access_token");
  };

  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        checkPermission() ? (
          React.cloneElement(Component, props)
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
