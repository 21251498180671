import ReducerRegistry from "../../../app/redux/ReducerRegistry";
import { ACTION_TYPES } from "./actionTypes";

const initState = {
  users: [],
};

ReducerRegistry.register("common", (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_USERS:
      return { ...state, users: action.data };

    default:
      return state;
  }
});
