/*
 * 23-01-2022 MDA-363 LuânĐT login with google authen
 */
import React from "react";

function ReportFrame({ link }) {
  return (
    <iframe
      src={link}
      style={{
        display: "block",
        border: "none",
        height: "100vh",
        width: "100%",
        paddingBottom: "60px",
      }}
    ></iframe>
  );
}
export default React.memo(ReportFrame);
