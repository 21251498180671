export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",

  GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_ERROR: "GET_USER_INFO_ERROR",

  POST_ACTIVITY_SUCCESS: "POST_ACTIVITY_SUCCESS",
  POST_ACTIVITY_ERROR: "POST_ACTIVITY_ERROR",

  GET_TOTAL_LO_SUCCESS: "GET_TOTAL_LO_SUCCESS",
  GET_TOTAL_LO_ERROR: "GET_TOTAL_LO_ERROR",

  GET_ALL_CLASS_MATE_SUCCESS: "GET_ALL_CLASS_MATE_SUCCESS",
  GET_ALL_CLASS_MATE_ERROR: "GET_ALL_CLASS_MATE_SUCCESS",

  GET_LIST_SUBJECT_ERROR: "GET_LIST_SUBJECT_ERROR",
  GET_LIST_SUBJECT_SUCCESS: "GET_LIST_SUBJECT_SUCCESS",

  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_ERROR: "UPDATE_PROFILE_ERROR",

  SET_USER_SUMMARY: Symbol("SET_USER_SUMMARY"),
};
