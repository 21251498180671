/*
 * 23-01-2022 MDA-363 LuânĐT login with google authen
 */
import React from "react";
import { Result, Button } from "antd";

function PermissionReject() {
  const logout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <Result
      status="403"
      title="403"
      subTitle="Tài khoản của bạn chưa được cấp quyền xem. Vui lòng liên hệ quản lí để được phân quyền !!!"
      extra={
        <Button type="primary" onClick={() => logout()}>
          Sử dụng tài khoản khác
        </Button>
      }
    />
  );
}
export default React.memo(PermissionReject);
