import axios from "axios";

const getHeaders = {
  Authorization: JSON.parse(localStorage.getItem("access_token")),
};

export const httpGET = (
  url,
  params,
  successCallback,
  failCallback,
  timeout = 10000
) => {
  return axios
    .get(url, {
      params,
      headers: getHeaders,
      timeout,
    })
    .then((response) => {
      successCallback(response.data);
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      }
      failCallback(error);
    });
};

export const httpPUT = (url, params, successCallback, failCallback) => {
  return axios
    .put(url, params, {
      headers: getHeaders,
    })
    .then((response) => {
      successCallback(response.data);
    })
    .catch((error) => {
      failCallback(error);
    });
};

export const httpPOST = (url, params, successCallback, failCallback) => {
  return axios({
    method: "POST",
    headers: getHeaders,
    data: params,
    url,
  })
    .then((response) => {
      successCallback(response.data);
    })
    .catch((error) => {
      failCallback(error);
    });
};

export const httpPOSTUnauthenticated = (
  url,
  params,
  successCallback,
  failCallback
) => {
  return axios({
    method: "POST",
    data: params,
    url,
  })
    .then((response) => {
      successCallback(response.data);
    })
    .catch((error) => {
      failCallback(error);
    });
};
