import React from "react";
import "../assets/layout.scss";
import Header from "./Header";

export default ({ children, containerStyle, id }) => {
  return (
    <div
      style={{ ...containerStyle, display: "flex", flexDirection: "column" }}
      className="lika-app"
    >
      <Header logoImg="/logo.svg" />
      <div id={id} className="lika-app-content">
        {children}
      </div>
    </div>
  );
};
