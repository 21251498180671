import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ReducerRegistry from "../app/redux/ReducerRegistry";
import * as authenticationReducer from "./reducers/authenticationReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["caches", "auth", "base"],
};

const reducers = ReducerRegistry.combineReducers(persistConfig);

let middlewares = [thunk];

const store = createStore(reducers, applyMiddleware(...middlewares));

const persistor = persistStore(store, {}, () => {});

export { persistor, authenticationReducer };

export default store;
